// Generated by Framer (4ba8877)

import { addFonts, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const serializationHash = "framer-pC8AJ"

const variantClassNames = {Ju3uCK95m: "framer-v-vax5ny"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "Ju3uCK95m", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-vax5ny", className, classNames)} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"Ju3uCK95m"} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "rgba(255, 255, 255, 0.65)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backdropFilter: "blur(0px)", backgroundColor: "rgba(255, 128, 0, 0.85)", borderBottomLeftRadius: 16, borderBottomRightRadius: 16, borderTopLeftRadius: 16, borderTopRightRadius: 16, boxShadow: "inset 0px 0.6021873017743928px 0.6021873017743928px -1.25px rgba(255, 255, 255, 0.53992), inset 0px 2.288533303243457px 2.288533303243457px -2.5px rgba(255, 255, 255, 0.47668), inset 0px 10px 10px -3.75px rgba(255, 255, 255, 0.1875)", WebkitBackdropFilter: "blur(0px)", ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "RlM7TWFucm9wZS1yZWd1bGFy", "--framer-font-family": "\"Manrope\", \"Manrope Placeholder\", sans-serif", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))"}}>Selvindsigt</motion.p></React.Fragment>} className={"framer-u1vbzd"} fonts={["FS;Manrope-regular"]} layoutDependency={layoutDependency} layoutId={"JLaOcR0Ed"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-pC8AJ.framer-1a6m8sb, .framer-pC8AJ .framer-1a6m8sb { display: block; }", ".framer-pC8AJ.framer-vax5ny { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 6px 16px 6px 16px; position: relative; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-pC8AJ .framer-u1vbzd { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-pC8AJ.framer-vax5ny { gap: 0px; } .framer-pC8AJ.framer-vax5ny > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-pC8AJ.framer-vax5ny > :first-child { margin-top: 0px; } .framer-pC8AJ.framer-vax5ny > :last-child { margin-bottom: 0px; } }", ".framer-pC8AJ[data-border=\"true\"]::after, .framer-pC8AJ [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 31
 * @framerIntrinsicWidth 114
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerwbo5w71Ya: React.ComponentType<Props> = withCSS(Component, css, "framer-pC8AJ") as typeof Component;
export default Framerwbo5w71Ya;

Framerwbo5w71Ya.displayName = "Emner";

Framerwbo5w71Ya.defaultProps = {height: 31, width: 114};

addFonts(Framerwbo5w71Ya, [{explicitInter: true, fonts: [{family: "Manrope", source: "fontshare", style: "normal", url: "https://framerusercontent.com/third-party-assets/fontshare/wf/2TYFCBHUANEXS6QGR5EQDUNAFH6LSWM3/AYNOU3VEA4LRTDNKJQUFNVNUTYSGOUOP/UXO4O7K2G3HI3D2VKD7UXVJVJD26P4BQ.woff2", weight: "400"}]}], {supportsExplicitInterCodegen: true})